<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row v-if="currClient">
			<b-col v-if="!$screen.sm" md="12">
				<h1 v-html="rendTitlePage" :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]" />
			</b-col>
			<b-col cols="12" xl="12">
				<transition name="slide">
					<!-- bg-variant="secondary" text-variant="white"  -->
					<!-- bg-secondary text-white -->
					<b-card no-body>
						<b-card-header v-if="$screen.sm">
							<h4 v-html="rendTitlePage" />
						</b-card-header>
						<b-card-body>
							<b-row>
								<b-col md="12" lg="9">
									<b-row>
										<b-col>
											<b-card>
												<b-row>
													<b-col md="3">
														<b-form-group :label="FormMSG(122332, 'Country')" label-for="supplier-country-filter">
															<CountrySelector
																v-model="countryKeyFilter"
																custom-id="supplier-country-filter"
																@change="handleCountryChange"
																:country-selected="countryKeyFilter"
															/>
														</b-form-group>
													</b-col>
													<b-col>
														<GoogleAutoComplete
															style="width: 100%"
															:label="FormMSG(3543, 'Search client')"
															:country="googleAutoCompleteRestrictedCountry"
															@placechanged="handleAutoCompleteChange"
														/>
													</b-col>
												</b-row>
												<b-row>
													<b-col>
														<b-form-group :label="FormMSG(343, 'Name')" label-for="new-supplier-name">
															<b-form-input
																id="new-supplier-name"
																v-model="clientPayload.name"
																type="text"
																required
																:placeholder="FormMSG(3331, 'Company name')"
															/>
														</b-form-group>
													</b-col>
												</b-row>
											</b-card>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<b-card>
												<b-card-title>{{ FormMSG(4545, 'Main data') }}</b-card-title>
												<b-row>
													<b-col>
														<b-row>
															<b-col :cols="$screen.width < 992 ? 12 : 0" :class="`${$screen.width < 992 ? 'mb-3' : ''}`">
																<VatChecker @change="handleVatChange" />
															</b-col>
															<b-col>
																<b-form-group :label="FormMSG(31, 'Company type')" label-for="supplier-input-phone">
																	<b-form-select :options="companyTypes" v-model="clientPayload.companyType"></b-form-select>
																</b-form-group>
															</b-col>
														</b-row>
														<b-row v-if="clientPayload.companyType !== 0">
															<b-col sm="6">
																<b-form-group :label="FormMSG(1, 'Contact name')" label-for="supplier-input-contact-name">
																	<b-form-input
																		id="supplier-input-contact-name"
																		v-model="clientPayload.contactName"
																		type="text"
																		required
																		:placeholder="FormMSG(2, 'Contact name...')"
																	/>
																</b-form-group>
															</b-col>
															<b-col sm="6">
																<b-form-group
																	:label="FormMSG(3, 'Contact function')"
																	label-for="supplier-input-contact-function"
																>
																	<b-form-input
																		id="supplier-input-contact-function"
																		v-model="clientPayload.contactFunction"
																		type="text"
																		required
																		:placeholder="FormMSG(4, 'Contact function...')"
																	/>
																</b-form-group>
															</b-col>
														</b-row>
														<b-row>
															<b-col :cols="$screen.width < 992 ? 12 : 0">
																<b-form-group :label="FormMSG(1212, 'Phone')" label-for="supplier-input-phone">
																	<b-form-input
																		id="supplier-input-phone"
																		v-model="clientPayload.phone"
																		type="text"
																		:placeholder="FormMSG(1212, 'Phone')"
																	/>
																</b-form-group>
															</b-col>
															<b-col>
																<b-form-group :label="FormMSG(11, 'eMail')" label-for="supplier-input-email">
																	<b-form-input
																		id="supplier-input-email"
																		v-model="clientPayload.email"
																		type="email"
																		required
																		:placeholder="FormMSG(11, 'eMail')"
																	/>
																</b-form-group>
															</b-col>
														</b-row>
													</b-col>
												</b-row>
											</b-card>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<b-card>
												<b-card-title>{{ FormMSG(12, 'Address') }}</b-card-title>

												<b-card-body>
													<AddressForm
														has-country-key-parent-filter
														:filterable-by-country="false"
														:country-key="countryKeyFilter"
														:auto-completed-data="handleAutoCompleteAddressFromName"
														:edit-data="clientAddressPayload"
														:fill-search="searchAddressFromVat"
														@change="handleAdress"
													/>
												</b-card-body>
											</b-card>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<b-card>
												<b-card-title>{{ FormMSG(13, 'Bank') }}</b-card-title>
												<b-card-body>
													<b-row>
														<b-col>
															<b-form-group :label="FormMSG(14, 'Iban')" label-for="supplier-input-iban">
																<b-form-input
																	id="supplier-input-iban"
																	v-model="clientPayload.iban"
																	:placeholder="FormMSG(14, 'Iban')"
																/>
															</b-form-group>
														</b-col>
														<b-col>
															<b-form-group :label="FormMSG(15, 'BIC')" label-for="supplier-input-bic">
																<b-form-input
																	id="supplier-input-bic"
																	v-model="clientPayload.bic"
																	:placeholder="FormMSG(15, 'BIC')"
																/>
															</b-form-group>
														</b-col>
													</b-row>
												</b-card-body>
											</b-card>
										</b-col>
									</b-row>
									<b-row>
										<b-col>
											<b-card>
												<b-card-title>{{ FormMSG(25, 'Locations') }}</b-card-title>
												<b-card-body>
													<b-row>
														<b-col cols="12" sm="12">
															<client-location-list :items="clientPayload.locations" />
														</b-col>
													</b-row>
													<b-row>
														<b-col cols="8" sm="8" v-if="$screen.width >= 992">&nbsp;</b-col>
														<b-col :cols="$screen.width < 992 ? 12 : 4" :class="`${$screen.width < 992 ? 'mt-2' : ''}`">
															<b-form-group>
																<b-button type="button" variant="primary" size="md" block @click="openLocationModal">
																	<span>{{ FormMSG(26, 'Add location') }}</span>
																</b-button>
															</b-form-group>
														</b-col>
													</b-row>
												</b-card-body>
											</b-card>
										</b-col>
									</b-row>
								</b-col>
								<b-col md="12" lg="3">
									<b-row>
										<b-col md="12">
											<b-form-group>
												<b-button class="full-width-on-mobile" variant="success" size="md" :disabled="form_busy" @click="goBack">
													{{ FormMSG(234234, 'Back to clients') }}
													<i class="icon-action-undo" />
												</b-button>
											</b-form-group>
										</b-col>
										<b-col md="12">
											<b-form-group>
												<b-button
													type="submit"
													class="full-width-on-mobile"
													variant="primary"
													size="md"
													:disabled="form_busy"
													@click="onSubmit"
												>
													<span v-if="!form_busy && !is_create">{{ FormMSG(21, 'Save modifications') }}</span>
													<span v-if="!form_busy && is_create">{{ FormMSG(23, 'Save client') }}</span>
													<span v-if="form_busy">
														<b-spinner label="Spinning" />
														{{ FormMSG(16, 'Update in progress') }}
													</span>
												</b-button>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
								<client-location-modal
									v-model="isOpenLocationModal"
									:location="newLocation"
									@client-location-modal:cancel="onLocationCanceled"
									@client-location-modal:updated="onLocationUpdated"
								/>
							</b-row>
						</b-card-body>
					</b-card>
				</transition>
			</b-col>
		</b-row>
	</errorContainer>
</template>

<script>
import { isNil, omit, getObjectFromValue } from '@/shared/utils';
import { addClient, getClient, updateClient } from '@/cruds/clients.crud';
import AddressForm from '@/components/Forms/Address';
import GoogleAutoComplete from '@/components/GoogleAutoComplete';
import CountrySelector from '@/components/Selectors/CountrySelector';
import languageMessages from '@/mixins/languageMessages';
// import CustomerSelector from '@/components/customer/CustomerSelector' // TODO: not used ?
import VatChecker from '@/components/VatChecker';
import ClientLocationList from '@/components/ClientLocation/List';
import ClientLocationModal from '@/components/ClientLocation/Modal';
import { mapActions } from 'vuex';
import { store } from '@/store';
import globalMixin from '@/mixins/global.mixin';

const fieldsclient = {
	name: null,
	vat: null,
	phone: null,
	email: null,
	iban: null,
	bic: null,
	companyType: null,
	type: 0
};

const fieldsaddress = {
	street: null,
	number: null,
	box: null,
	zip: null,
	state: null,
	city: null,
	country: null,
	countryCode: null,
	countryKey: null,
	lat: 0,
	lng: 0
};

export default {
	name: 'ClientsFocusPage',
	components: {
		AddressForm,
		// CustomerSelector, // TODO: not used ?
		CountrySelector,
		GoogleAutoComplete,
		VatChecker,
		ClientLocationList,
		ClientLocationModal
	},
	mixins: [languageMessages, globalMixin],
	data() {
		return {
			filter: '',
			hv: 'dark',
			erreur: {},
			warning: '',
			selectedColor: 'primary',
			currClient: null,
			handleAutoCompleteAddressFromName: null,
			searchAddressFromVat: null,
			clientPayload: {},
			clientAddressPayload: {},
			form_busy: false,
			client_selector: null,
			is_create: false,
			countryKeyFilter: 16965, // Belgium's countryKey
			googleAutoCompleteRestrictedCountry: null,
			isOpenLocationModal: false,
			newLocation: {}
		};
	},
	computed: {
		/**
		 * @return {String}
		 */
		rendTitlePage() {
			const _t = this.FormMSG(10, 'Client');
			const name = isNil(this.currClient.name) ? '' : `<b>: ${this.currClient.name}</b>`;
			return _t + name;
		},
		//For
		isEditable() {
			const ed = this.editData;
			if (isNil(ed)) return true;
			return ed.validated === 0 || ed.validated === 4;
		},

		/**
		 * @return {Array}
		 */
		optionsForCountryCode() {
			return this.FormMenu(9);
		},
		companyTypes() {
			return this.FormMenu(1325);
		}
	},
	watch: {
		optionsForCountryCode: {
			handler() {
				this.getCountryIsoFromKey(this.countryKeyFilter);
			},
			deep: true
		},
		'$route.params': {
			async handler(val) {
				const { id } = val;
				await this.initClientsList(id);
				await this.initCountrySearch();
				this.initDefaultRestrictedCountry();
			},
			immediate: true
		}
	},
	methods: {
		async initCountrySearch() {
			if (isNil(store.state) || isNil(store.getCurrentProjectConfig())) {
				return;
			}
			const project = store.getCurrentProjectConfig();

			/**
			 * @TODO
			 * Filter legalEntity whith "isOwner" when it's became more than one
			 */
			const legalEntity = project.legalEntities.length > 0 ? project.legalEntities[0] : null;

			if (!isNil(this.clientAddressPayload.countryKey) && this.clientAddressPayload.countryKey > 0) {
				this.countryKeyFilter = this.clientAddressPayload.countryKey;
			} else if (!isNil(legalEntity) && !isNil(legalEntity.address) && legalEntity.address.countryKey > 0) {
				this.countryKeyFilter = legalEntity.address.countryKey;
			}
		},
		handleVatChange(vat) {
			this.clientPayload.name = vat.name;
			if (isNil(vat.address)) return;
			this.searchAddressFromVat = vat.address;
		},

		initDefaultRestrictedCountry() {
			const key = this.countryKeyFilter;
			this.googleAutoCompleteRestrictedCountry = this.getCountryIsoFromKey(key);
		},

		/**
		 * @param {Object} value
		 */
		handleAutoCompleteChange(value) {
			this.clientPayload.name = value.name;
			this.handleAutoCompleteAddressFromName = {
				...this.clientAddressPayload,
				street: value.route,
				city: value.locality,
				number: value.street_number,
				state: value.administrative_area_level_1,
				zip: value.postal_code,
				country: value.country,
				lat: value.latitude,
				lng: value.longitude
			};
		},

		setClientSelector(payload) {
			this.client_selector = payload;
		},

		async initClientsList(id) {
			this.is_create = isNaN(id);
			if (!this.is_create) {
				this.currClient = await getClient(id);
				// console.log({client: this.currClient});
				this.fillForm(this.currClient);
			} else {
				this.currClient = Object.assign({}, fieldsclient);
				this.currClient.address = Object.assign({}, fieldsaddress);
				this.fillForm(this.currClient);
			}
		},

		fillForm(client) {
			// console.log({ FILL: client })
			if (isNil(client)) return;
			this.clientPayload = omit(['address'], client);
			this.clientAddressPayload = client.address;
		},

		async onSubmit() {
			try {
				if (!isNil(this.clientPayload.name) && this.clientPayload.name !== '') {
					this.form_busy = true;
					if (!this.is_create) {
						const editedClient = await updateClient({
							id: this.$route.params.id,
							client: this.clientPayload,
							address: this.clientAddressPayload
						});
						this.fillForm(editedClient);
						this.createToastForMobile(this.FormMSG(17, 'Success'), this.FormMSG(18, 'Update successfull'), '', 'success');
					} else {
						const add_client = await addClient(this.clientPayload.name);
						const editedClient = await updateClient({
							id: add_client.id,
							client: this.clientPayload,
							address: this.clientAddressPayload
						});
						this.fillForm(editedClient);
						this.createToastForMobile(this.FormMSG(17, 'Success'), this.FormMSG(22, 'Add client successfull'), '', 'success');
						this.is_create = false;
						await this.$router.push(`/clientsList/${add_client.id}`);
					}

					this.form_busy = false;
				} else {
					this.createToastForMobile('Error', this.FormMSG(24, 'The name of client is required'), '', 'danger');
				}
			} catch (e) {
				console.log(e.message);
			}
		},

		/**
		 * @param {Object} address
		 */
		handleAdress(address) {
			this.clientAddressPayload = address;
		},

		goBack() {
			this.$router.go(-1);
		},

		/**
		 * @param {Object} country
		 */
		handleCountryChange(country) {
			this.googleAutoCompleteRestrictedCountry = country.code.toLowerCase();
		},

		/**
		 * @param {Number} key
		 */
		getCountryIsoFromKey(key) {
			const country = getObjectFromValue(this.optionsForCountryCode, 'value', key);
			return !isNil(country) ? country.text.toLowerCase() : '';
		},
		...mapActions({
			setNewLocation: 'locations/setNewLocation'
		}),
		async openLocationModal() {
			if (!this.is_create) {
				await this.setNewLocation(this.clientPayload.id);
				this.newLocation = this.$store.getters['locations/currLocation'];
				if (isNil(this.newLocation)) return;
				if (this.clientPayload.locations === undefined) {
					this.$set(this.clientPayload, 'locations', []);
				}
				this.clientPayload.locations.push(this.newLocation);
				this.isOpenLocationModal = true;
			} else {
				this.createToastForMobile(this.FormMSG(5, 'Warning'), this.FormMSG(414, 'Please save the client detail first'), '', 'warning');
			}
		},
		onLocationCanceled() {
			this.isOpenLocationModal = false;
		},
		onLocationUpdated(payload) {}
	}
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}

input[type='texte'] {
	width: 100%;
	padding: 12px 20px;
	margin: 4px 0;
	box-sizing: border-box;
	border: none;
	background-color: white;
	color: white;
}
</style>
