var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _vm.currClient
        ? _c(
            "b-row",
            [
              !_vm.$screen.sm
                ? _c("b-col", { attrs: { md: "12" } }, [
                    _c("h1", {
                      class: [
                        `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                        ,
                        { "is-pwa": _vm.$isPwa() },
                      ],
                      domProps: { innerHTML: _vm._s(_vm.rendTitlePage) },
                    }),
                  ])
                : _vm._e(),
              _c(
                "b-col",
                { attrs: { cols: "12", xl: "12" } },
                [
                  _c(
                    "transition",
                    { attrs: { name: "slide" } },
                    [
                      _c(
                        "b-card",
                        { attrs: { "no-body": "" } },
                        [
                          _vm.$screen.sm
                            ? _c("b-card-header", [
                                _c("h4", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.rendTitlePage),
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _c(
                            "b-card-body",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "12", lg: "9" } },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-card",
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        { attrs: { md: "3" } },
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.FormMSG(
                                                                    122332,
                                                                    "Country"
                                                                  ),
                                                                "label-for":
                                                                  "supplier-country-filter",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "CountrySelector",
                                                                {
                                                                  attrs: {
                                                                    "custom-id":
                                                                      "supplier-country-filter",
                                                                    "country-selected":
                                                                      _vm.countryKeyFilter,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.handleCountryChange,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.countryKeyFilter,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.countryKeyFilter =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "countryKeyFilter",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c(
                                                            "GoogleAutoComplete",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                label:
                                                                  _vm.FormMSG(
                                                                    3543,
                                                                    "Search client"
                                                                  ),
                                                                country:
                                                                  _vm.googleAutoCompleteRestrictedCountry,
                                                              },
                                                              on: {
                                                                placechanged:
                                                                  _vm.handleAutoCompleteChange,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.FormMSG(
                                                                    343,
                                                                    "Name"
                                                                  ),
                                                                "label-for":
                                                                  "new-supplier-name",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id: "new-supplier-name",
                                                                    type: "text",
                                                                    required:
                                                                      "",
                                                                    placeholder:
                                                                      _vm.FormMSG(
                                                                        3331,
                                                                        "Company name"
                                                                      ),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .clientPayload
                                                                        .name,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.clientPayload,
                                                                          "name",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "clientPayload.name",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-card",
                                                [
                                                  _c("b-card-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          4545,
                                                          "Main data"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  class: `${
                                                                    _vm.$screen
                                                                      .width <
                                                                    992
                                                                      ? "mb-3"
                                                                      : ""
                                                                  }`,
                                                                  attrs: {
                                                                    cols:
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? 12
                                                                        : 0,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "VatChecker",
                                                                    {
                                                                      on: {
                                                                        change:
                                                                          _vm.handleVatChange,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            31,
                                                                            "Company type"
                                                                          ),
                                                                        "label-for":
                                                                          "supplier-input-phone",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-select",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              options:
                                                                                _vm.companyTypes,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientPayload
                                                                                  .companyType,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientPayload,
                                                                                    "companyType",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientPayload.companyType",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm.clientPayload
                                                            .companyType !== 0
                                                            ? _c(
                                                                "b-row",
                                                                [
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        sm: "6",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-group",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.FormMSG(
                                                                                  1,
                                                                                  "Contact name"
                                                                                ),
                                                                              "label-for":
                                                                                "supplier-input-contact-name",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  id: "supplier-input-contact-name",
                                                                                  type: "text",
                                                                                  required:
                                                                                    "",
                                                                                  placeholder:
                                                                                    _vm.FormMSG(
                                                                                      2,
                                                                                      "Contact name..."
                                                                                    ),
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .clientPayload
                                                                                      .contactName,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.clientPayload,
                                                                                        "contactName",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "clientPayload.contactName",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        sm: "6",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-group",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                _vm.FormMSG(
                                                                                  3,
                                                                                  "Contact function"
                                                                                ),
                                                                              "label-for":
                                                                                "supplier-input-contact-function",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  id: "supplier-input-contact-function",
                                                                                  type: "text",
                                                                                  required:
                                                                                    "",
                                                                                  placeholder:
                                                                                    _vm.FormMSG(
                                                                                      4,
                                                                                      "Contact function..."
                                                                                    ),
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .clientPayload
                                                                                      .contactFunction,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.clientPayload,
                                                                                        "contactFunction",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "clientPayload.contactFunction",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols:
                                                                      _vm
                                                                        .$screen
                                                                        .width <
                                                                      992
                                                                        ? 12
                                                                        : 0,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            1212,
                                                                            "Phone"
                                                                          ),
                                                                        "label-for":
                                                                          "supplier-input-phone",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              id: "supplier-input-phone",
                                                                              type: "text",
                                                                              placeholder:
                                                                                _vm.FormMSG(
                                                                                  1212,
                                                                                  "Phone"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientPayload
                                                                                  .phone,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientPayload,
                                                                                    "phone",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientPayload.phone",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-col",
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          _vm.FormMSG(
                                                                            11,
                                                                            "eMail"
                                                                          ),
                                                                        "label-for":
                                                                          "supplier-input-email",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              id: "supplier-input-email",
                                                                              type: "email",
                                                                              required:
                                                                                "",
                                                                              placeholder:
                                                                                _vm.FormMSG(
                                                                                  11,
                                                                                  "eMail"
                                                                                ),
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientPayload
                                                                                  .email,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientPayload,
                                                                                    "email",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientPayload.email",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-card",
                                                [
                                                  _c("b-card-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          12,
                                                          "Address"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-card-body",
                                                    [
                                                      _c("AddressForm", {
                                                        attrs: {
                                                          "has-country-key-parent-filter":
                                                            "",
                                                          "filterable-by-country": false,
                                                          "country-key":
                                                            _vm.countryKeyFilter,
                                                          "auto-completed-data":
                                                            _vm.handleAutoCompleteAddressFromName,
                                                          "edit-data":
                                                            _vm.clientAddressPayload,
                                                          "fill-search":
                                                            _vm.searchAddressFromVat,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleAdress,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-card",
                                                [
                                                  _c("b-card-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(13, "Bank")
                                                      )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-card-body",
                                                    [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      _vm.FormMSG(
                                                                        14,
                                                                        "Iban"
                                                                      ),
                                                                    "label-for":
                                                                      "supplier-input-iban",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      attrs: {
                                                                        id: "supplier-input-iban",
                                                                        placeholder:
                                                                          _vm.FormMSG(
                                                                            14,
                                                                            "Iban"
                                                                          ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .clientPayload
                                                                            .iban,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.clientPayload,
                                                                              "iban",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "clientPayload.iban",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-col",
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      _vm.FormMSG(
                                                                        15,
                                                                        "BIC"
                                                                      ),
                                                                    "label-for":
                                                                      "supplier-input-bic",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      attrs: {
                                                                        id: "supplier-input-bic",
                                                                        placeholder:
                                                                          _vm.FormMSG(
                                                                            15,
                                                                            "BIC"
                                                                          ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .clientPayload
                                                                            .bic,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.clientPayload,
                                                                              "bic",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "clientPayload.bic",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-card",
                                                [
                                                  _c("b-card-title", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          25,
                                                          "Locations"
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "b-card-body",
                                                    [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "client-location-list",
                                                                {
                                                                  attrs: {
                                                                    items:
                                                                      _vm
                                                                        .clientPayload
                                                                        .locations,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _vm.$screen.width >=
                                                          992
                                                            ? _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "8",
                                                                    sm: "8",
                                                                  },
                                                                },
                                                                [_vm._v(" ")]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              class: `${
                                                                _vm.$screen
                                                                  .width < 992
                                                                  ? "mt-2"
                                                                  : ""
                                                              }`,
                                                              attrs: {
                                                                cols:
                                                                  _vm.$screen
                                                                    .width < 992
                                                                    ? 12
                                                                    : 4,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-group",
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      attrs: {
                                                                        type: "button",
                                                                        variant:
                                                                          "primary",
                                                                        size: "md",
                                                                        block:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.openLocationModal,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                26,
                                                                                "Add location"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "12", lg: "3" } },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "full-width-on-mobile",
                                                      attrs: {
                                                        variant: "success",
                                                        size: "md",
                                                        disabled: _vm.form_busy,
                                                      },
                                                      on: { click: _vm.goBack },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              234234,
                                                              "Back to clients"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                      _c("i", {
                                                        staticClass:
                                                          "icon-action-undo",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "full-width-on-mobile",
                                                      attrs: {
                                                        type: "submit",
                                                        variant: "primary",
                                                        size: "md",
                                                        disabled: _vm.form_busy,
                                                      },
                                                      on: {
                                                        click: _vm.onSubmit,
                                                      },
                                                    },
                                                    [
                                                      !_vm.form_busy &&
                                                      !_vm.is_create
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  21,
                                                                  "Save modifications"
                                                                )
                                                              )
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      !_vm.form_busy &&
                                                      _vm.is_create
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  23,
                                                                  "Save client"
                                                                )
                                                              )
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm.form_busy
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c("b-spinner", {
                                                                attrs: {
                                                                  label:
                                                                    "Spinning",
                                                                },
                                                              }),
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      16,
                                                                      "Update in progress"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("client-location-modal", {
                                    attrs: { location: _vm.newLocation },
                                    on: {
                                      "client-location-modal:cancel":
                                        _vm.onLocationCanceled,
                                      "client-location-modal:updated":
                                        _vm.onLocationUpdated,
                                    },
                                    model: {
                                      value: _vm.isOpenLocationModal,
                                      callback: function ($$v) {
                                        _vm.isOpenLocationModal = $$v
                                      },
                                      expression: "isOpenLocationModal",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }